module.exports = {
  /* DEV Server URL'S 
=================================================== */
 /*  URL: 'https://tests.noton.dev/cw-noton/graphql',
  Auth: 'https://identity-test.mycw.in/cw-identity/oauth/token', */

  /* Test Server URL'S 
=================================================== */
/*    URL:'https://s20q1.mycw.in/cw-noton/graphql',
   Auth:'https://s20q1.mycw.in/cw-identity/oauth/token' */

/* OFC-Test Server URL'S 
=================================================== */

/*	URL:'https://sofc-test-analytics.noton.dev/cw-noton/graphql',
        Auth:'https://auth-ofc-test-analytics.mycw.in/cw-identity/oauth/token' */
	
/* OFC-Live Server URL'S 
=================================================== */

/*      URL:'https://sofc01.noton.dev/cw-noton/graphql',
      Auth:'https://sauth.cw.solutions/cw-identity/oauth/token' */


/* Pre-prod Server URL'S 
=================================================== */
 
/*     URL:'https://sapp.noton.dev/cw-noton/graphql',
      Auth:'https://s360app.mycw.in/cw-identity/oauth/token' */

/* Anandyas Server URL's
============================================ */
URL: 'https://s2anandyas.noton.dev/cw-noton/graphql',
Auth: 'https://ianandyas.cw.solutions/cw-identity/oauth/token'

/* Demo Server URL's
=============================================== */

/*	URL: 'https://sdemos.noton.dev/cw-noton/graphql',
	Auth: 'https://identity-demos.cw.solutions/cw-identity/oauth/token' */

/* CWOne Server URL's
=============================================== */

/*  URL: 'https://sone.noton.dev/cw-noton/graphql',
  Auth: 'https://sone.mycw.in/cw-identity/oauth/token' */

/* s01 Server URL's
=============================================== */

/*  URL: 'https://s01core.noton.dev/cw-noton/graphql',
  Auth: 'https://s01auth.cw.solutions/cw-identity/oauth/token' */

/* s02 Server URL's
=============================================== */

/*  URL: 'https://s02core.noton.dev/cw-noton/graphql',
  Auth: 'https://s02auth.cw.solutions/cw-identity/oauth/token' */

/* s03 Server URL's
=============================================== */

/*  URL: 'https://s03core.noton.dev/cw-noton/graphql',
  Auth: 'https://s03auth.cw.solutions/cw-identity/oauth/token' */

}

